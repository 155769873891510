<template>
  <div @click="goToOrder()" class="logo-container col-12 col-md-12 col-lg-12">
      <div class="col-8 col-md-12 col-lg-12 logo-image-container">
          <!-- <img class="logo-img" src="../../public/images/trixx-logo-white.svg" alt=""> -->
        <TrixxLogoWhite class="logo-img" />
      </div>
  </div>
</template>

<script>
import TrixxLogoWhite from "../icons/TrixxLogoWhite.vue";

export default {
    name: 'Logo',
    data() {
        return {}
    },
    components: {
        TrixxLogoWhite
    },
    methods: {
        goToOrder() {
            if (confirm("Това ще откаже поръчката ви!"))
                this.$router.go('order');
        }
    }
}
</script>

<style lang="scss">
.logo-container {
    padding: 0;
    background-color: rgb(107, 158, 156);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;

    .logo-image-container {
        display: flex;
        align-items: stretch;
        padding: 0;
        margin: auto auto;

        .logo-img {
            height: 4.5rem;
            width: 7.5rem;
            margin: auto;
        }
    }

    .logo-text {
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-family: "Open Sans", sans-serif;
            font-size: 1.6rem;
            margin: 0;
        }
    }
}
</style>