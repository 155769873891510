<template>
<div class="col-12 col-md-12 col-lg-12">
  <router-view />
  <notifications class="vue-notification vue-notification-authorized" position="bottom right" group="foo" />
  <notifications class="vue-notification vue-notification-unauthorized" position="bottom right" group="unfoo" />
  <notifications class="vue-notification vue-notification-bad-product" position="center top" group="bad-product" />
  <notifications class="vue-notification vue-notification-warning" position="center top" group="warning" />
</div>
</template>

<script>
import EventBus from './plugins/event-bus';

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    EventBus.$on('loggedIn', (firstName) => {
      this.$notify({
        group: 'foo',
        title: 'Добре дошли',
        text: 'Здравейте, ' + firstName,
        classes: "vue-notification-authorized",
        duration: 3000
      })
    })

    EventBus.$on('wrongCredentials', () => {
      this.$notify({
        group: 'unfoo',
        title: 'Грешни данни за вход',
        text: 'Въведените данни не съвпадат',
        classes: "vue-notification-unauthorized",
        duration: 3000
      })
    })
  }
};
</script>
<style lang="scss">
.vue-notification {
  color: white;
  border-left: none !important;

  .vue-notification-template {
    background-color: rgb(255, 204, 0);
  }

  .notification-title {
    padding: 0.1rem !important;
  }

  .notification-content {
    padding: 0.2rem !important;
  }
}

.vue-notification:hover {
  cursor: pointer;
}

.vue-notification-authorized {
  background-color: teal !important;
}

.vue-notification-unauthorized {
  background-color: rgb(228, 76, 66) !important;
}

.vue-notification-bad-product {
  background-color: transparent !important;
}

.vue-notification-warning {
  background-color: #00D4FD !important;
}
</style>
