<template>
  <div class="color-main col-12 col-md-12 col-lg-12">
      <p>4. Цвят</p>
      <div class="color-types col-10 col-md-10 col-lg-10">
          <div v-for="(color, index) in colors" :key="index" class="color-type col-5 col-md-5 col-lg-5">
              <v-checkbox @change="updateColor(color)" :disabled="colorSelected(color)" :label="color.name" :color="`#f6c244`" />
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'Color',
    data() {
        return {
            colors: [
                {
                    name: 'Бял',
                    selected: false,
                    abbreviation: 'white'
                },
                {
                    name: 'Бронзе',
                    selected: false,
                    abbreviation: 'bronze'
                },
                {
                    name: 'Кафяв',
                    selected: false,
                    abbreviation: 'brown'
                },
                {
                    name: 'Титан',
                    selected: false,
                    abbreviation: 'titan'
                },
                {
                    name: 'Черен',
                    selected: false,
                    abbreviation: 'black'
                },
                {
                    name: 'Сребро',
                    selected: false,
                    abbreviation: 'silver'
                }
            ]
        }
    },
    methods: {
        colorSelected(color) {
            return this.colors.some(_ => _.selected && _.name !== color.name)
        },
        updateColor(color) {
            color.selected = !color.selected;

            if(this.colors.some(_ => _.selected)) {
                var colorString = this.colors.find(_ => _.selected).abbreviation;

                if(this.$store.state.currentProduct.color === "")
                    this.$store.commit('setColor', colorString);
            } else {
                this.$store.commit('setColor', "");
            }
        }
    },
    mounted() {
        this.$store.commit('setColor', "");
    }
}
</script>

<style lang="scss">
.color-main {
    height: 9rem;
    color: white;
    font-family: "Open Sans", sans-serif;
    background-color: rgba(107, 158, 156, 0.4);
    margin-bottom: .625rem;

    p {
        font-size: 1.25rem;
        padding: 0.25rem !important;
        margin: 0;
    }

    .color-types {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-left: .5rem !important; 

        .color-type {
            height: 1.875rem;
        }
        
        .v-label {
            margin: 0px;
            color: white;
            font-size: 0.875rem;
        }
    }
}
</style>