import OrderMain from '../views/OrderMain.vue'
import Login from '../views/Login'
import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/order',
        component: OrderMain,
        name: 'order',
        // beforeEnter(to, from, next) {
        //     if(localStorage.getItem("maco-token")) next();
        //     else router.push({name: 'login'})
        // }
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
        beforeEnter(to, from, next) {
            if(!localStorage.getItem("maco-token")) next();
            else router.push({name: 'order'})
        }
    },
    {
        path: '/*',
        component: OrderMain,
        name: 'order',
        beforeEnter(to, from, next) {
            if(localStorage.getItem("maco-token")) next();
            else router.push({name: 'order'})
        }
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router;