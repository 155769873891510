<template>
  <div class="other-main col-12 col-md-12 col-lg-12" v-bind:class="{ 'disabled': isDisabled }">
      <p>5. Други</p>
      <div v-if="!productIsEdnoosov()" class="other-choices-dvuosov col-11 col-md-11 col-lg-11">
        <v-checkbox v-on:change="updateMicroventilation()" v-model="microventilation" label="Ножица за микровентилация" :color="`#f6c244`" />
        <v-checkbox v-on:change="updateBack22205()" v-model="back22205" label="Гръбче 22205 по хоризонтала" :color="`#f6c244`" />
        <v-checkbox v-on:change="updateBack211924()" v-model="back211924" label="Гръбче 211924 по хоризонтала за прозорци L>800 мм." :color="`#f6c244`" />
      </div>
      <div v-else class="other-choices-ednoosov col-11 col-md-11 col-lg-11">
        <v-checkbox v-on:change="updateMiddleClickingHinge()" v-model="middleClickingHinge" label="К-т средна чупеща панта с капачки според избрания цвят." :color="`#f6c244`" />
        <v-checkbox v-on:change="updateMiddleTapeHinge()" v-model="middleTapeHinge" label="К-т средна панта-лента (външна) с капачки според избрания цвят." :color="`#f6c244`" />
      </div>
  </div>
</template>

<script>
export default {
    name: 'Other',
    data() {
        return {
            microventilation: false,
            back22205: false,
            back211924: false,
            middleClickingHinge: false,
            middleTapeHinge: false,
            isDisabled: false
        }
    },
    methods: {
        updateMicroventilation() {
            this.$store.commit('setMicroventilation', this.microventilation);
        },
        updateBack22205() {
            this.$store.commit('setBack22205', this.back22205);
        },
        updateBack211924() {
            this.$store.commit('setBack211924', this.back211924);
        },
        updateMiddleClickingHinge() {
            this.$store.commit('setMiddleClickingHinge', this.middleClickingHinge);
        },
        updateMiddleTapeHinge() {
            this.$store.commit("setMiddleTapeHinge", this.middleTapeHinge);
        },
        productIsEdnoosov() {
            return this.$store.state.currentProduct.mechanismType === 'ednoosov';
        }
    },
    mounted() {
        this.isDisabled = this.$store.state.currentProduct.mechanismType === "padasht";
    }
}
</script>

<style lang="scss">
.disabled {
    pointer-events: none;
    opacity: .5;
}

.other-main {
    height: 19rem;
    background-color: rgba(107, 158, 156, 0.4);
    padding-bottom: 7.5rem !important;

    p {
        margin: 0;
        padding: .25rem !important;
        color: white;
        font-size: 1.25rem;
    }

    .v-label{
        margin: 0 !important;
        color: white !important;
        font-size: .875rem;
    }

    .other-choices-dvuosov, .other-choices-ednoosov {
        padding-left: 1.25rem !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        div {
            margin: 0;
        }
    }
}
</style>