<template>
  <div class="sizes-and-amount-main col-12 col-md-12 col-lg-12">
      <p>1. Размери и бройки</p>
      <div class="sizes col-12 col-md-12 col-lg-12">
          <div class="window-width col-12 col-md-12 col-lg-12">
            <input v-if="productHasWidth()" v-on:change="updateWidth()" v-model="width" @keypress="isNumber($event)" type="text" placeholder="широчина">
            <p v-if="productHasWidth()">мм.</p>
          </div>
          <div class="window-height col-11 col-md-10 col-lg-10">
              <img class="window-img" :src="productImage" alt="error">
              <div v-if="productHasHeight()" class="window-height-input-holder">
                <input v-on:change="updateHeight()" v-model="height" @keypress="isNumber($event)" type="text" placeholder="височина">
                <p>мм.</p>
              </div>
              <div v-else class="decorative-input"></div>
          </div>
      </div>
      <div class="amount col-12 col-md-12 col-lg-12">
          <div class="amount-input col-5 col-md-5 col-lg-5">
              <p>Брой обкови</p>
              <input v-on:change="updateAmount()" v-model="amount" @keypress="isNumber($event)" type="text" placeholder="">
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'SizeAndAmount',
    data() {
        return {
            width: undefined,
            height: undefined,
            amount: 0,
            productImage: undefined
        }
    },
    watch: {
        "$store.state.currentProduct.mechanismType": function() {
            this.getProductImage();
        }
    },
    methods: {
        updateWidth() {
            this.$store.commit('setWidth', parseInt(this.width));
        },
        updateHeight() {
            this.$store.commit('setHeight', parseInt(this.height));
        },
        updateAmount() {
            this.$store.commit('setAmount', parseInt(this.amount));
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        getProductImage() {
            try {
                const mechanism = this.$store.state.currentProduct.mechanismType;
                this.productImage = require(`../../../public/images/products/mcs-scheme-${mechanism}.png`);
            } catch (e) {
                e ? null : null;
            }
        },
        productHasWidth() {
            return this.$store.state.currentProduct.mechanismType !== "ednoosov";
        },
        productHasHeight() {
            return this.$store.state.currentProduct.mechanismType !== "padasht";
        }
    },
    mounted() {
        this.getProductImage();
    }
}
</script>

<style lang="scss">
.sizes-and-amount-main {
    height: 24.813rem;
    background-color: rgba(107, 158, 156, 0.4);
    font-family: "Open Sans", sans-serif;
    padding-bottom: 2.5rem !important;
    margin-bottom: .625rem;

    p {
        text-align: left;
        font-size: 1.25rem;
        color: white;
        padding: .25rem !important;
    }

    input {
        border-radius: 1.563rem;
        background-color: white;
        text-align: center;
        height: 2.188rem;
        width: 5.938rem;
        font-size: .813rem !important;
    }

    input:focus {
        border: .180rem solid rgb(246, 194, 68);//rgb(107, 158, 156);
        outline: none;
    }

    .sizes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        .window-width {
            min-height: 2.188rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            p {
                margin: 0;
                font-size: 0.875rem;
            }
        }

        .window-height {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: auto;

            input {
                margin: auto 0;
            }

            .window-img {
                width: 11.875rem;
                height: 13.75rem;
            }

            .window-height-input-holder {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;

                p {
                    margin: auto;
                    font-size: 0.875rem;
                }
            }

            .decorative-input {
                opacity: 0;
                width: 5.938rem;
            }
        }
    }

    .amount {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .amount-input {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: .313rem !important;

            p {
                font-size: 1rem;
            }

            input {
                width: 3.125rem;
            }
        }
    }
}
</style>