<template>
  <div class="articles-title col-12 col-md-12 col-lg-12">
      <h4>Списък артикули по поръчка номер: {{orderNr}}</h4>
  </div>
</template>

<script>
export default {
    name: 'ArticlesTitle',
    data() {
        return {
            orderNr: 0
        }
    },
    mounted() {
        this.orderNr = this.$store.state.ORDER_NR;
    }
}
</script>

<style lang="scss">
.articles-title {
    display: flex;
    align-items: center;
    height: 4.5rem;
    max-height: 4.5rem;
    background-color: rgba(246, 194, 68, 0.3);

    h4 {
        padding-left: .625rem !important;
        font-family: "Open Sans", sans-serif;
        color: white;
        margin: 0;
    }
}
</style>