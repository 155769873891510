<template>
<div class="product-main col-12 col-md-12 col-lg-12" v-click-outside="closeSubTypes">
    <div @click="openSubTypes()" class="product col-12 col-md-12 col-lg-12">
      <div class="name-image col-8 col-md-8 col-lg-8">
          <img class="product-type-image" :src="require('../../public/images/products/' + image + '.png')" alt="">
          <p>{{name}}</p>
      </div>
      <div class="icon col-2 col-md-2 col-lg-2">
          <img class="product-image" :src="imgSrc" alt="">
      </div>
    </div>
    <div id="sub-types" class="sub-types col-4 col-md-4 col-lg-4">
        <ProductSubType v-bind:subTypes="subTypes" />
    </div>
</div>
  
</template>

<script>
import ProductSubType from './ProductSubType'
import EventBus from '../plugins/event-bus'
import ClickOutside from "vue-click-outside";

export default {
    name: 'ProductType',
    components: {
        ProductSubType
    },
    props: [
        'image',
        'name',
        'subTypes',
        'abbreviation'
    ],
    data() {
        return {
            imgSrc: require('../../public/images/mcs-button-arrow-right.png'),
            panelOpen: false
        }
    },
    methods: {
        openSubTypes() {
            EventBus.$emit('close-all-product-types');

            if (!this.subTypes.length) {
                this.$store.commit('setTempMechanismType', this.abbreviation);
                this.$store.commit('setTempMechanismTypeBG', this.name);
                this.$store.commit("setWingType", this.abbreviation);
                this.$store.commit("setWingTypeBG", this.abbreviationBG);
                this.$store.commit("setMechanismType");
                this.$store.commit("setMechanismTypeBG");
                this.$store.commit("resetCurrentProduct");
                EventBus.$emit("showMartinaType");
                EventBus.$emit("closeSubTypes");
                return;
            }

            if(this.panelOpen) {
                this.panelOpen = false;
                return;
            }
            
            var productType = this.$el.getElementsByClassName('product')[0];
            getComputedStyle(productType).marginLeft == "0px" ? productType.style.marginLeft =  ".438rem" : 
                productType.style.marginLeft =  "0px"

            var subTypes = this.$el.getElementsByClassName('sub-types')[0];
            subTypes.style.display == "block" ? subTypes.style.display = "none" : subTypes.style.display = "block";

            this.imgSrc.includes('orange') ? this.imgSrc = require('../../public/images/mcs-button-arrow-right.png') : 
                this.imgSrc = require('../../public/images/mcs-button-arrow-right-orange.png')

            this.$store.commit('setTempMechanismType', this.abbreviation);
            this.$store.commit('setTempMechanismTypeBG', this.name);

            this.panelOpen = true;
        },
        closeSubTypes() {
            this.$el.getElementsByClassName('product')[0].style.marginLeft = "0px";
            this.$el.getElementsByClassName('sub-types')[0].style.display = "none";
            this.imgSrc = require('../../public/images/mcs-button-arrow-right.png');
        }
    },
    mounted() {
        EventBus.$on('closeSubTypes', () => {
            this.closeSubTypes()
        })
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss">
.product-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 3rem;
}

.product {
    cursor: pointer;
    background-color: rgb(107, 158, 156);
    height: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin-left: 0px;

    .product-type-image {
        width: 2.3rem;
        height: 2.3rem;
    }

    .name-image {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: .2rem;

        p {
            margin-bottom: 0px;
            font-family: "Open Sans", sans-serif;
            font-size: .9rem;
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        .product-image {
            margin: auto auto;
            height: 1.1rem;
            width: 1.1rem;
            -khtml-user-select: none;
            -o-user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;
        }
    }

    .sub-types {
        display: none;
    }
}
</style>