import Vuex from "vuex";
import Vue from "vue";
import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ORDER_NR: 0,
    listOfArticles: [],
    listOfProducts: [],
    tempMechanismType: "",
    tempMechanismTypeBG: "",
    currentProduct: {
      id: 0,
      mechanismType: "",
      mechanismTypeBG: "",
      wing: "",
      wingBG: "",
      martina: "",
      hinge: "",
      width: 0,
      height: 0,
      amount: 0,
      upperFramePin: 0,
      lowerSashPin: 0,
      openingDirection: "",
      profileSystem: "",
      profileType: "",
      profileSystemShort: "",
      color: "",
      other: {
        microventilation: false,
        back22205: false,
        back211924: false,
        middleClickingHinge: false,
        middleTapeHinge: false,
      },
      additional: {
        snapper: false,
        security: false,
        lockingSnapper: false,
        rollSnapper: false,
      },
    },
  },
  mutations: {
    setListOfArticles(state, value) {
      state.listOfArticles = value;
    },
    setTempMechanismType(state, value) {
      state.tempMechanismType = value;
    },
    setTempMechanismTypeBG(state, value) {
      state.tempMechanismTypeBG = value;
    },
    setMechanismType(state) {
      state.currentProduct.mechanismType = state.tempMechanismType;
    },
    setMechanismTypeBG(state) {
      state.currentProduct.mechanismTypeBG = state.tempMechanismTypeBG;
    },
    setWingType(state, value) {
      state.currentProduct.wing = value;
    },
    setWingTypeBG(state, value) {
      state.currentProduct.wingBG = value;
    },
    setMartinaType(state, value) {
      state.currentProduct.martina = value;
    },
    setHingeType(state, value) {
      state.currentProduct.hinge = value;
    },
    setWidth(state, value) {
      state.currentProduct.width = value;
    },
    setHeight(state, value) {
      state.currentProduct.height = value;
    },
    setAmount(state, value) {
      state.currentProduct.amount = value;
    },
    setUpperFramePin(state, value) {
      state.currentProduct.upperFramePin = value;
    },
    setLowerSashPin(state, value) {
      state.currentProduct.lowerSashPin = value;
    },
    setOpeningDirection(state, value) {
      state.currentProduct.openingDirection = value;
    },
    setProfileSystem(state, value) {
      state.currentProduct.profileSystem = value;
    },
    setProfileType(state, value) {
      state.currentProduct.profileType = value;
    },
    setProfileSystemShort(state, value) {
      state.currentProduct.profileSystemShort = value;
    },
    setColor(state, value) {
      state.currentProduct.color = value;
    },
    setMicroventilation(state, value) {
      state.currentProduct.other.microventilation = value;
    },
    setBack22205(state, value) {
      state.currentProduct.other.back22205 = value;
    },
    setBack211924(state, value) {
      state.currentProduct.other.back211924 = value;
    },
    setMiddleClickingHinge(state, value) {
      state.currentProduct.other.middleClickingHinge = value;
    },
    setMiddleTapeHinge(state, value) {
      state.currentProduct.other.middleTapeHinge = value;
    },
    setSnapper(state, value) {
      state.currentProduct.additional.snapper = value;
    },
    setSecurity(state, value) {
      state.currentProduct.additional.security = value;
    },
    setLockingSnapper(state, value) {
      state.currentProduct.additional.lockingSnapper = value;
    },
    setRollSnapper(state, value) {
      state.currentProduct.additional.rollSnapper = value;
    },
    emptyListOfProducts(state) {
      state.listOfProducts = [];
    },
    addCurrentProductToList(state) {
      const existingProduct = state.listOfProducts.find((product) =>
        _.isEqual(
          _.omit(product, ["amount"]),
          _.omit(state.currentProduct, ["amount"])
        )
      );

      if (existingProduct) {
        existingProduct.amount += state.currentProduct.amount;
      } else {
        state.listOfProducts.push(
          JSON.parse(JSON.stringify(state.currentProduct))
        );
      }
    },
    removeProductFromList(state, product) {
      if (
        state.listOfProducts.some(
          (_) => JSON.stringify(_) === JSON.stringify(product)
        )
      ) {
        this.state.listOfProducts = state.listOfProducts.filter(
          (_) => JSON.stringify(_) !== JSON.stringify(product)
        );
      }
    },
    resetCurrentProduct(state) {
      state.currentProduct = {
        ...state.currentProduct,
        martina: "",
        hinge: "",
        width: 0,
        height: 0,
        amount: 0,
        upperFramePin: 0,
        lowerSashPin: 0,
        openingDirection: "",
        profileSystem: "",
        profileType: "",
        profileSystemShort: "",
        color: "",
        other: {
          microventilation: false,
          back22205: false,
          back211924: false,
          middleClickingHinge: false,
          middleTapeHinge: false,
        },
        additional: {
          snapper: false,
          security: false,
          lockingSnapper: false,
          rollSnapper: false,
        },
      };
    },
  },
  actions: {},
  modules: {},
  getters: {
    isLogged: (state) => {
      return state.isLogged;
    },
  },
});
