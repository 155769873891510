import Vue from "vue";
import App from "./App.vue";
import router from "../src/route/router";
import vuetify from "./plugins/vuetify";
import store from "./store/index";
import Notifications from "vue-notification";
import JsonExcel from "vue-json-excel";

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.component("download-excel", JsonExcel);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
  data: {
    json_fields: {
      "Артикул": "articleNr",
      "Описание": "description",
      "м.е": "бр.",
      "Количество": "quantity"
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  },
}).$mount("#app");
