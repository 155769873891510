<template>
  <div class="additional-elements col-12 col-md-12 col-lg-12" v-bind:class="{ 'disabled': isDisabled }">
      <p>6. Допълнителни</p>
      <div v-if="!productIsEdnoosov()" class="additional-elements-types col-10 col-md-10 col-lg-10">
            <v-checkbox v-on:change="updateSnapper()" v-model="snapper" :label="`Шнапер`" :color="`#f6c244`" />
            <v-checkbox v-on:change="updateSecurity()" v-model="security" :label="`Защита против разпробиване`" :color="`#f6c244`" />
      </div>
      <div v-else class="additional-elements-types col-10 col-md-10 col-lg-12">
            <v-checkbox v-on:change="updateLockingSnapper()" v-model="snapper" :label="`Шнапер заключващ в наср. на осигурителката`" :color="`#f6c244`" />
            <v-checkbox v-on:change="updateRollSnapper()" v-model="security" :label="`Шнапер с ролка`" :color="`#f6c244`" />
      </div>
  </div>
</template>

<script>
export default {
    name: 'AdditionalElements',
    data() {
        return {
            snapper: false,
            security: false,
            lockingSnapper: false,
            rollSnapper: false,
            isDisabled: false
        }
    },
    methods: {
        updateSnapper() {
            this.$store.commit('setSnapper', this.snapper);
        },
        updateSecurity() {
            this.$store.commit('setSecurity', this.security);
        },
        updateLockingSnapper() {
            this.$store.commit('setLockingSnapper', this.lockingSnapper);
        },
        updateRollSnapper() {
            this.$store.commit('setRollSnapper', this.rollSnapper);
        },
        productIsEdnoosov() {
            return this.$store.state.currentProduct.mechanismType === 'ednoosov';
        }
    },
    mounted() {
        this.isDisabled = this.$store.state.currentProduct.mechanismType === "padasht";
    }
}
</script>

<style lang="scss">
.disabled {
    pointer-events: none;
    opacity: .5;
}

.additional-elements {
    height: 9.28rem;
    color: white;
    padding: .25rem !important;
    font-family: "Open Sans", sans-serif;
    background-color: rgba(107, 158, 156, 0.4);

    p {
        margin: 0;
        font-size: 1.25rem;
    }

    .additional-elements-types {
        padding-left: 1rem !important;
        padding-bottom: .125rem !important;
        
        .v-label {
            margin: 0px;
            color: white;
            font-size: .875rem;
        }

         div {
            margin: 0;
        }
    }
}
</style>