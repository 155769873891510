<template>
    <div class="articles-list col-12 col-md-12 col-lg-12">
        <v-simple-table class="articles-list-table" height="70vh">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Артикул</th>
                        <th class="text-left">Описание</th>
                        <th class="text-left">м.е.</th>
                        <th class="text-left">Количество</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(article,index) in listOfArticles" :key="index">
                        <td>{{ article.articleNr }}</td>
                        <td>{{ article.description }}</td>
                        <td>{{ article.measurementEntity }}</td>
                        <td>{{ article.quantity }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ArticlesList',
    data() {
        return {
            listOfArticles: []
        }
    },
    async mounted() {
        let body = { products: this.$store.state.listOfProducts };
        // this.listOfArticles = (await axios.post("http://localhost:3000/get-hardware", body)).data;
        this.listOfArticles = (await axios.post("https://ugliest-jonie-bobirok.koyeb.app/get-hardware", body)).data;
        this.$store.commit("setListOfArticles", this.listOfArticles);
    }
}
</script>

<style>
.articles-list-table {
    height: 100%;
    max-height: 80%;
    background-color: transparent !important;
    color: white !important;
}

.articles-list-table:hover {
    background-color: transparent !important;
}

th {
    color: white !important;
    border-top: none !important;
}

tr:hover {
    background-color: transparent !important;
}
</style>