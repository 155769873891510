<template>
  <div class="lower-sash-hinge-pin-type-main col-12 col-md-12 col-lg-12">
      <div class="header-text col-12 col-md-12 col-lg12">
          <p class="col-12 col-md-12 col-lg-12">Моля, изберете дебелината на щифта на долната панта крило:</p>
      </div>
      <div class="buttons col-12 col-md-6 col-lg-6">
        <v-btn @click="showHingeType(3)" x-large class="pin-btn col-4 col-md-4 col-lg-4">3мм.</v-btn>
        <v-btn @click="showHingeType(5)" x-large class="pin-btn col-4 col-md-4 col-lg-4">5мм.</v-btn>
      </div>
  </div>
</template>

<script>
import EventBus from '../../plugins/event-bus';

export default {
    name: 'LowerSashHingePinType',
    data() {
        return {

        }
    },
    methods: {
        showHingeType(pinTickness) {
            this.$store.commit('setLowerSashPin', pinTickness);
            EventBus.$emit('showSpecifications');
        }
    }
}
</script>

<style lang="scss">
.lower-sash-hinge-pin-type-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
        .pin-btn {
            background-color: rgb(246, 194, 68) !important;
            color: white;
            border-radius: 1.25rem 1.25rem 1.25rem 1.25rem !important;
            font-size: 1.2rem !important;
            text-transform: none !important;
            font-family: "Open Sans", sans-serif;
            height: 9rem !important;
        }
    }

    .header-text {
        color: white;
        padding-bottom: 1.1rem !important;
        font-size: 1.2rem;
        white-space: nowrap;
        text-align: center;
    }
}
</style>