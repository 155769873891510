<template>
  <div class="hinge-types-main col-12 col-md-12 col-lg-12">
      <div class="header-text col-12 col-md-12 col-lg12">
          <p class="col-12 col-md-12 col-lg-12">Моля, изберете вида на долната панта на крилото:</p>
      </div>
      <div class="buttons col-12 col-md-12 col-lg-12">
        <v-btn @click="showSpecifications('straight')" x-large class="hinge-btn col-4 col-md-4 col-lg-4">Права панта <br> на перото</v-btn>
        <v-btn @click="showSpecifications('falz')" x-large class="hinge-btn col-4 col-md-4 col-lg-4">Фалц <br> панта</v-btn>
      </div>
  </div>
</template>

<script>
import EventBus from '../../plugins/event-bus'

export default {
    name: 'HingeType',
    data() {
        return {
            
        }
    },
    methods: {
        showSpecifications(hinge) {
            this.$store.commit('setHingeType', hinge);

            if(this.$store.state.currentProduct.hinge === "straight") {
                EventBus.$emit('showLowerSashHingePinTypeVisible');
            } else {
                EventBus.$emit('showSpecifications');
            }
        }
    }
}
</script>

<style lang="scss">
.hinge-types-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .header-text {
        padding-bottom: 1.1rem !important;
        color: white;
        text-align: center;
        font-size: 1.2rem;
    }

    .buttons {
        display: flex;
        justify-content: space-around;
    }

    .hinge-btn {
        background-color: rgb(246, 194, 68) !important;
        color: white;
        border-radius: 1.25rem 1.25rem 1.25rem 1.25rem !important;
        font-size: 1.2rem !important;
        text-transform: none !important;
        font-family: "Open Sans", sans-serif;
        height: 9rem !important;
    }
}
</style>