<template>
  <div class="container-fluid order-main col-12 col-md-12 col-lg-12">
    <div class="left-aside col-12 col-md-2 col-lg-2">
        <div class="logo-wrapper col-12 col-md-4 col-lg-12">
            <Logo />
        </div>
        <div class="left-asside-bottom-container col-12 col-md-4 col-lg-12">
            <div class="product-list col-12 col-md-12 col-lg-12">
                <ProductList v-if="productTypesVisible" />
            </div>
            <div class="account-banner-wrapper col-12 col-md-12 col-lg-12">
                <AccountBanner />
            </div>
        </div>
    </div>
    <div v-if="!articlesBarVisible" class="mid-side col-12 col-md-8 col-lg-8">
        <div v-if="orderSelectorVisible" class="order-selector-wrapper col-12 col-md-6 col-lg-6">
            <OrderSelector />
        </div>
        <div v-if="martinaTypesVisible" class="martina-types-wrapper col-12 col-md-6 col-lg-6">
            <MartinaType />
        </div>
        <div v-if="upperFrameHingePinTypeVisible" class="upper-frame-hinge-pin-wrapper col-12 col-md-12 col-lg-12">
            <UpperFrameHingePinType />
        </div>
        <div v-if="hingeTypesVisible" class="hinge-types-wrapper col-12 col-md-6 col-lg-6">
            <HingeType />
        </div>
        <div v-if="lowerSashHingePinTypeVisible" class="lower-sash-hinge-pin-wrapper col-12 col-md-12 col-lg-12">
            <LowerSashHingePinType />
        </div>
        <div v-if="specificationsVisible" class="specifications-wrapper col-12 col-md-12 col-lg-12">
            <SpecificationsMain />
        </div>
    </div>
    <div v-if="!articlesBarVisible" class="right-aside col-12 col-md-2 col-lg-2">
        <div v-if="productTypesVisible" class="order-number-wrapper col-12 col-md-12 col-lg-12">
            <OrderNumber v-bind:orderNr="this.$store.state.ORDER_NR"/>
        </div>
        <div v-if="productTypesVisible" class="order-cart col-12 col-md-12 col-lg-12">
            <ProductCart />
        </div>
    </div>
    <div v-if="articlesBarVisible" class="articles-bar col-12 col-md-10 col-lg-10">
        <ArticlesWrapper />
    </div>
  </div>
</template>

<script>
import Logo from "../components/Logo"
import OrderSelector from "../components/OrderSelector"
import AccountBanner from "../components/AccountBanner"
import ProductList from "../components/ProductList"
import OrderNumber from "../components/OrderNumber"
import ProductCart from "../components/ProductCart"
import MartinaType from "../components/hinge-martina-choices/MartinaType"
import HingeType from "../components/hinge-martina-choices/HingeType"
import UpperFrameHingePinType from "../components/hinge-martina-choices/UpperFrameHingePinType"
import LowerSashHingePinType from "../components/hinge-martina-choices/LowerSashHingePinType"
import ArticlesWrapper from "../components/articles-components/ArticlesWrapper"
import SpecificationsMain from "../components/specifications-components/SpecificationsMain"
import EventBus from "../plugins/event-bus"

export default {
    name: 'OrderMain',
    components: {
        Logo,
        OrderSelector,
        AccountBanner,
        ProductList,
        OrderNumber,
        ProductCart,
        MartinaType,
        HingeType,
        UpperFrameHingePinType,
        LowerSashHingePinType,
        SpecificationsMain,
        ArticlesWrapper
    },
    data() {
        return {
            productTypesVisible: false,
            martinaTypesVisible: false,
            hingeTypesVisible: false,
            upperFrameHingePinTypeVisible: false,
            lowerSashHingePinTypeVisible: false,
            specificationsVisible: false,
            articlesBarVisible: false,
            orderSelectorVisible: true
        }
    },
    methods: {
    },
    mounted() {
        EventBus.$on('createOrder', () => {
            this.productTypesVisible = true;
            this.orderSelectorVisible = false;
            this.martinaTypesVisible = false;
            this.hingeTypesVisible = false;
            this.specificationsVisible = false;
            this.lowerSashHingePinTypeVisible = false;
            this.upperFrameHingePinTypeVisible = false;
        })
        EventBus.$on('showMartinaType', () => {
            this.martinaTypesVisible = true;
            this.hingeTypesVisible = false;
            this.specificationsVisible = false;
            this.lowerSashHingePinTypeVisible = false;
            this.upperFrameHingePinTypeVisible = false;
        })
        EventBus.$on('showHingeTypes', () => {
            this.hingeTypesVisible = true;
            this.martinaTypesVisible = false;
            this.specificationsVisible = false;
            this.upperFrameHingePinTypeVisible = false;
            this.lowerSashHingePinTypeVisible = false;
        }),
        EventBus.$on('showUpperFrameHingePinType', () => {
            this.upperFrameHingePinTypeVisible = true;
            this.hingeTypesVisible = false;
            this.martinaTypesVisible = false;
            this.specificationsVisible = false;
            this.lowerSashHingePinTypeVisible = false;
        }),
        EventBus.$on('showLowerSashHingePinTypeVisible', () => {
            this.lowerSashHingePinTypeVisible = true;
            this.hingeTypesVisible = false;
            this.martinaTypesVisible = false;
            this.specificationsVisible = false;
            this.upperFrameHingePinTypeVisible = false;
        })
        EventBus.$on('showSpecifications', () => {
            this.specificationsVisible = true;
            this.lowerSashHingePinTypeVisible = false;
            this.hingeTypesVisible = false;
            this.martinaTypesVisible = false;
            this.upperFrameHingePinTypeVisible = false;
        }),
        EventBus.$on('showArticlesBar', (data) => {
            this.articlesBarVisible = true;
            this.specificationsVisible = false;
            this.upperFrameHingePinTypeVisible = false;
            this.lowerSashHingePinTypeVisible = false;
            this.hingeTypesVisible = false;
            this.martinaTypesVisible = false;
            EventBus.$emit('updateProductList', data);
        }),
        EventBus.$on('returnToSpecifications', () => {
            this.productTypesVisible = true;
            this.specificationsVisible = true;
            this.articlesBarVisible = false;
            this.upperFrameHingePinTypeVisible = false;
            this.lowerSashHingePinTypeVisible = false;
            this.hingeTypesVisible = false;
            this.martinaTypesVisible = false;
        })
    }
}
</script>

<style lang="scss">
body {
    padding: 0px !important;
    margin: 0px !important;
}

.order-main {
    padding: 0 !important;
    background-color: rgb(56, 63, 67);
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .left-aside {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 100%;

        .logo-wrapper {
            max-height: 6rem;
            height: 4rem;
        }
        .left-asside-bottom-container {
            max-height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .product-list {
                padding: 0;
                max-height: 25rem;
            }

            .account-banner-wrapper {
                flex: 1;
                align-self: flex-end;
                max-height: 4rem;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .mid-side {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-aside {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .order-number-wrapper {
            max-height: 4.5rem;
        }

        .order-cart {
            align-self: flex-end;
            max-height: 85%;
        }
    }
}
.order-selector-wrapper, .martina-types-wrapper, .hinge-types-wrapper, .upper-frame-hinge-pin-wrapper, .lower-sash-hinge-pin-wrapper {
    padding: 0;
    height: 15rem;
}

 .specifications-wrapper {
     height: 100vh;
     padding: 0px;
     overflow: auto;
 }

 .articles-bar {
     align-self: flex-end;
 }
</style>