<template>
    <div class="selector col-12 col-md-12 col-lg-12">
        <v-btn @click="openNewOrderScreen()" class="order-btn primary x-large">Нова поръчка</v-btn>
        <NewOrder class="col-12 col-md-12 col-lg-12" v-if="newOrderScreen"/>
        <v-btn v-else class="order-btn primary x-large">Отвори поръчка</v-btn>
    </div>
</template>

<script>
import NewOrder from './NewOrder'

export default {
    name: 'OrderSelector',
    components: {
        NewOrder
    },
    data() {
        return {
            newOrderScreen: false
        }
    },
    methods: {
        openNewOrderScreen() {
            this.$store.commit('emptyListOfProducts');
            this.newOrderScreen = !this.newOrderScreen;
        }
    }
}
</script>

<style>
* {
    padding: 0;
    margin: 0;
}

.selector {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 60%;
    margin: auto auto;
}

.order-btn {
    background-color: rgb(107, 158, 156) !important;
    color: white;
    height: 3rem !important;
    border-radius: 1.563rem 1.563rem 1.563rem 1.563rem !important;
    font-size: 1.6rem !important;
    text-transform: none !important;
    font-family: "Open Sans", sans-serif;
}
</style>