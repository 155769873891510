<template>
  <div class="order-number col-12 col-md-12 col-lg-12">
      <h3>Поръчка номер: {{orderNr}}</h3>
  </div>
</template>

<script>
export default {
    name: 'OrderNumber',
    props: [
        'orderNr'
    ],
    data() {
        return {

        }
    }

}
</script>

<style lang="scss">
.order-number {
    background-color: rgba(246, 194, 68, 0.3);
    max-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        color: white;
        background-color: transparent;
        font-size: 1.125rem;
        font-family: "Open Sans", sans-serif;
        text-align: center;
        margin: 0;
    }
}
</style>