<template>
  <div class="product-cart col-12 col-md-12 col-lg-12">
      <div class="products col-12 col-md-12 col-lg-12 p-1">
        <div class="product-text d-flex flex-row col-12 col-md-12 col-lg-12" 
            v-for="(product, index) in listOfProducts" :key="index"> 
            <p class="col-11 col-md-11 col-lg-11">
                {{index+1}}. H={{product.height}}; L={{product.width}}; 
                {{product.profileSystemShort}}; 
                {{product.color}} - {{product.amount}} бр.
            </p>
            <button @click="removeProduct(product)" class="delete-product-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </button>
        </div>
      </div>
      <div class="buttons col-12 col-md-12 col-lg-12">
          <v-btn @click="save()" x-large class="options-btn"><img src="../../public/images/mcs-button-zapis.png">Запис</v-btn>
          <v-btn x-large class="options-btn"><img src="../../public/images/mcs-button-otkaz.png">Отказ</v-btn>
      </div>
  </div>
</template>

<script>
import EventBus from '../plugins/event-bus';

export default {
    name: 'ProductCart',
    data() {
        return {
            listOfProducts: []
        }
    },
    methods: {
        async save() {
            EventBus.$emit('showArticlesBar');
        },
        removeProduct(product) {
            this.$store.commit('removeProductFromList', product);
            EventBus.$emit('product-list-updated');
        }
    },
    mounted() {
        EventBus.$on('product-list-updated', () => {
            this.listOfProducts = this.$store.state.listOfProducts;
        })
    },
    created() {
        this.listOfProducts = this.$store.state.listOfProducts;
    }
}
</script>

<style lang="scss">
.product-cart {
    max-height: 100%;
    height: 100%;
    background-color: rgba(246, 194, 68, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .products {
        max-height: 80%;
        overflow: auto;

        .product-text {
            font-family: "Open Sans", sans-serif;
            color: white;
            font-size: .75rem;
            padding: .125rem !important;

            .delete-product-btn {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-top: .1rem !important;
            }

            .delete-product-btn:hover {
                background-color: #f6c244;
                border: 1px solid #f6c244;
                color: white;
            }
        }
    }

    .buttons {
        max-height: 10%;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .options-btn {
            padding: .25rem !important;
            background-color: rgb(246, 194, 68) !important;
            color: white;

            img {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}
</style>