<template>
  <div class="buttons-main col-12 col-md-12 col-lg-12">
    <div class="up-buttons-panel col-12 col-md-12 col-lg-12">
      <div class="print-share-save col-12 col-md-12 col-lg-12">
        <v-btn x-large class="options-btn-articles"><img
            src="../../../public/images/buttons/mcs-button-print.png">Печат</v-btn>
        <v-btn x-large class="options-btn-articles"><img
            src="../../../public/images/buttons/mcs-button-spodeli.png">Сподели</v-btn>

        <v-btn x-large class="options-btn-articles">
          <download-excel class="options-btn-articles" :name="'obkov' + this.$store.state.ORDER_NR + '.xls'"
            :data="exportToExcel()" :fields="json_fields">
            <img src="images/buttons/mcs-button-zapishi_vuv_fail.png" />
            Запиши във файл
          </download-excel>
        </v-btn>
      </div>
      <v-btn @click="returnToSpecifications()" x-large class="options-btn-articles"><img
          src="../../../public/images/buttons/mcs-button-nazad.png">Назад</v-btn>
    </div>
    <v-btn x-large class="options-btn-articles"><img
        src="../../../public/images/buttons/mcs-button-otkaz.png">Изход</v-btn>
  </div>
</template>

<script>
// import {json2excel} from "js2excel";
import EventBus from "../../plugins/event-bus";
import { translateJsonToExcel } from "../../services/json-to-excel-translator";

export default {
  name: 'ArticlesButtons',
  data() {
    return {

    }
  },
  methods: {
    returnToSpecifications() {
      EventBus.$emit('returnToSpecifications');
    },
    exportToExcel() {
      return translateJsonToExcel(this.$store.state.listOfArticles);
    }
  }
}
</script>

<style lang="scss">
.buttons-main {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .up-buttons-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 50%;

    .print-share-save {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 55%;
      margin-bottom: .625rem !important;
      padding-top: .938rem !important;
    }
  }
}

.options-btn-articles {
  padding: .25rem !important;
  background-color: rgb(246, 194, 68) !important;
  color: white !important;
  text-align: left !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: none !important;
  font-size: .800rem !important;
  overflow: hidden;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>