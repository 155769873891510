<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 330 203.6"
    style="enable-background: new 0 0 330 203.6"
    xml:space="preserve"
  >
    <g id="Layer_1">
      <g>
        <rect x="134.7" y="75.9" class="st0" width="38" height="5.7" />

        <rect
          x="133.7"
          y="102.4"
          transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 258.9291 -48.4988)"
          class="st0"
          width="40.1"
          height="5.7"
        />
        <path
          class="st0"
          d="M196.8,88.8v5.6c-7,0-11.6,2.8-12.1,4.9c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.4,0.1,0.6v25h-5.7V99.7
			c0-0.1,0-0.3,0-0.4C179.4,93.3,187,88.8,196.8,88.8z"
        />
        <path class="st0" d="M176.7,99.3L176.7,99.3c0,0.1,0,0.3,0,0.4V99.3z" />
        <path
          class="st0"
          d="M184.8,99.3v1c-0.1-0.2-0.1-0.4-0.1-0.6C184.7,99.6,184.7,99.4,184.8,99.3L184.8,99.3z"
        />

        <rect
          x="187.6"
          y="104.2"
          transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 312.8469 -98.7701)"
          class="st0"
          width="36.4"
          height="5.7"
        />
        <polygon
          class="st0"
          points="247.1,125.3 240.3,125.3 231,111.6 227.6,106.5 225.7,103.8 215.6,88.8 222.4,88.8 229.4,99 
			231,101.5 234.4,106.5 237.2,110.7 		"
        />
        <polygon
          class="st0"
          points="286.9,125.3 280,125.3 270.4,111 270.4,111 267,106 267,106 255.3,88.8 262.2,88.8 267.1,96 
			270.4,100.9 273.8,106 275,107.7 		"
        />
        <polygon
          class="st0"
          points="225.4,109.7 228.9,114.8 221.8,125.3 214.9,125.3 		"
        />
        <polygon
          class="st0"
          points="231,101.5 234.4,106.5 231,111.6 231,111.6 227.6,106.5 		"
        />
        <polygon
          class="st0"
          points="246.4,88.8 236.6,103.4 233.1,98.3 239.6,88.8 		"
        />
        <polygon
          class="st0"
          points="264.8,109.1 268.2,114.2 260.7,125.3 253.9,125.3 		"
        />
        <polygon
          class="st0"
          points="270.4,100.9 273.8,106 270.4,111 267,106 267,106 		"
        />
        <polygon
          class="st0"
          points="285.4,88.8 275.9,102.8 272.5,97.7 278.6,88.8 		"
        />
        <circle class="st0" cx="205.8" cy="78.8" r="2.8" />
      </g>
    </g>
    <g id="Layer_2">
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="104.1281"
        y1="106.1905"
        x2="104.1281"
        y2="75.9505"
      >
        <stop offset="0" style="stop-color: #6a9b77" />
        <stop offset="1" style="stop-color: #6a9e9c" />
      </linearGradient>
      <polygon class="st1" points="112.9,76 95.4,106.2 112.9,76 	" />
      <path
        class="st2"
        d="M45.7,64.6c-2,0-3.2,2.1-2.2,3.8l0.9,1.6l3,5.3l36.1,62.5c1,1.7,3.5,1.7,4.4,0L124,75.3l1.3-2.3l2.6-4.5
		c1-1.7-0.2-3.8-2.2-3.8H45.7z"
      />
      <g>
        <path
          class="st0"
          d="M116.6,76l-30.9,53.5L54.8,76h5.8l15.6,27.1l2.3,4c1.3,2.2,4.6,1.3,4.6-1.2V76H66.3l-2.9-5H108l-2.9,5H88.2
			v30c0,2.5,3.4,3.5,4.6,1.2l0.6-1L110.9,76H116.6z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M84.6,114.7L84.6,114.7c-0.1-0.1-0.1-0.1-0.1-0.2L84.6,114.7z"
        />
        <path
          class="st0"
          d="M84.6,114.7L84.6,114.7c-0.1-0.1-0.1-0.1-0.1-0.2L84.6,114.7z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "TrixxLogoWhite"
};
</script>

<style type="text/css">
      .st0 {
        fill: #ffffff;
      }
      .st1 {
        fill: url(#SVGID_1_);
      }
      .st2 {
        fill: none;
        stroke: #ffffff;
        stroke-width: 3;
        stroke-miterlimit: 10;
      }
    </style>
