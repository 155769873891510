<template>
  <div class="new-order-main col-3 col-md-2 col-lg-2">
      <p>Моля, въведете номер на поръчката:</p>
      <input v-model="orderNr" placeholder="Поръчка" class="order-number-field col-8 col-md-8 col-lg-8">
      <v-btn @click="createOrder()" class="btn-begin primary small col-6 col-md-6 col-lg-6">Започни</v-btn>
  </div>
</template>

<script>
import EventBus from '../plugins/event-bus'

export default {
    name: 'NewOrder',
    data() {
        return {
            orderNr: 0
        }
    },
    methods: {
        createOrder() {
            this.$store.state.ORDER_NR = this.orderNr
            EventBus.$emit('createOrder');
        }
    }
}
</script>

<style lang="scss">
.new-order-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 15rem;
    margin: auto auto;
    
    p { 
        margin: 0px !important;
        font-size: 1.2rem;
        white-space: nowrap;
        color: white;
    }

    .order-number-field {
        max-height: 2.188rem;
        border: .012rem solid rgb(107, 158, 156);
        border-radius: 1.563rem 1.563rem 1.563rem 1.563rem;
        color: white;
        text-align: center;
    }

    .order-number-field:focus {
        outline: none;
    }

    .btn-begin {
        background-color: rgb(107, 158, 156) !important;
        border-radius: 1.563rem 1.563rem 1.563rem 1.563rem !important;
    }
}
</style>