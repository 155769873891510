<template>
  <div class="martina-types-main col-12 col-md-12 col-lg-12">
      <div class="header-text col-12 col-md-12 col-lg-12">
          <p>Моля, изберете вида на мартината:</p>
      </div>
      <div class="buttons col-12 col-md-12 col-">
        <v-btn @click="showHingeTypes('varying')" x-large class="martina-btn col-4 col-md-4 col-lg-4">Варираща <br> мартина</v-btn>
        <v-btn @click="showHingeTypes('fixed')" x-large class="martina-btn col-4 col-md-4 col-lg-4">Фиксирана <br> мартина</v-btn>
      </div>
  </div>
</template>

<script>
import EventBus from "../../plugins/event-bus";

export default {
    name: 'MartinaType',
    data() {
        return {}
    },
    methods: {
        showHingeTypes(martina) {
            this.$store.commit("setMartinaType", martina);
            EventBus.$emit("showUpperFrameHingePinType");
        }
    },
    mounted() {
        if (this.$store.state.currentProduct.mechanismType === "padasht") {
            this.$store.commit("setMartinaType", "varying");
            EventBus.$emit("showUpperFrameHingePinType");
        }
    }
}
</script>

<style lang="scss">
.martina-types-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .header-text {
        font-size: 1.2rem;
        text-align: center;
        padding-bottom: 1.1rem !important;
        color: white;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .martina-btn {
        background-color: rgb(246, 194, 68) !important;
        color: white;
        border-radius: 1.25rem 1.25rem 1.25rem 1.25rem !important;
        font-size: 1.2rem !important;
        text-transform: none !important;
        font-family: "Open Sans", sans-serif;
        height: 9rem !important;
    }
}
</style>