<template>
<div class="account-banner-wrapper col-12 col-md-12 col-lg-12">
  <div @click="expand()" class="account-banner-main col-12 col-md-12 col-lg-12">
    <div class="initials col-10 col-md-10 col-lg-10">
        <div class="acronym">
            <h3>{{acronym}}</h3>
        </div>
        <div class="names col-8 col-md-8 col-lg-8">
            <p>{{firstName}} {{lastName}}</p>
        </div>
    </div>
    <div class="icon col-2 col-md-2 col-lg-2">
        <img id="imgArrow" src="../../public/images/mcs-button-arrow-up.png" alt="">
    </div>
    </div>
    <div @click="logOut()" v-if="expanded" class="log-out-wrapper col-12 col-md-12 col-lg-12">
        <div class="log-out-icon">
            <img class="logout-image" src="../../public/images/mcs-button-otkaz.png" alt="">
        </div>
        <div class="log-out-text col-6 col-md-9 col-lg-9">
            <p>Изход</p>
        </div>
    </div>
</div>
</template>

<script>
import * as jwt from 'jsonwebtoken';

export default {
    name: 'AccountBanner',
    data() {
        return{
            firstName: 'Васил',
            lastName: 'Андров',
            acronym: '',
            expanded: false
        }
    },
    methods: {
        expand() {
            this.expanded = !this.expanded;
            let imgArrow = document.getElementById('imgArrow');
            imgArrow.setAttribute("style", this.expanded ? "transform: rotate(90deg)" : "transform: rotate(360deg)");
        },
        logOut() {
            localStorage.removeItem("maco-token");
            this.$router.push({name: 'login'})
        },
        getNames() {
            var token = localStorage.getItem("maco-token");
            var decodedUser = jwt.decode(token);
            this.firstName = decodedUser.firstName;
            this.lastName = decodedUser.lastName;
        }
    },
    mounted() {
        this.acronym = this.firstName[0] + this.lastName[0];
        this.getNames()
    }
}
</script>

<style lang="scss">
.account-banner-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .log-out-wrapper {
        padding-top: 1rem !important;
        padding-bottom: 0.5rem !important;
        background-color: rgb(107, 158, 156);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .log-out-text {
            p {
                margin-bottom: 0rem;
                margin-left: 0.5rem;
                color: white;
            }
        }

        .logout-image {
            height: 2.5rem;
            width: 2.5rem;
            margin-left: 0.5rem;
        }
    }
}

.account-banner-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(107, 158, 156);
    padding: 0;
    color: white;
    font-family: "Open Sans";

    .initials {
        height: 85%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .acronym {
        padding: 0;
        margin-left: 0.5rem;
        height: 2.5rem;
        width: 2.5rem;
        text-align: center;
        border-radius: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(55,63,65);

        h3 {
            font-size: 1.4rem;
            margin: auto auto;
        }
    }

    .names {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 1rem;
        margin-left: 0.5rem;
        height: 100%;

        p {
            margin-bottom: 0px;
        }
    }
    
    #imgArrow {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.account-banner-wrapper:hover {
    cursor:default;
}

.account-banner-main:hover {
    cursor: pointer;
}
</style>