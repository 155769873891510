<template>
  <div @click="showMartinaType()" class="sub-products col-12 col-md-12 col-lg-12">
      <div @click.stop="showMartinaType(product)" v-for="(product, index) in subTypes" :key="index" class="sub-product col-12 col-md-12 col-lg-12">
          <img class="sub-product-image" :src="require('../../public/images/products/' + product.img + '.png')" alt="">
      </div>
  </div>
</template>

<script>
import EventBus from "../plugins/event-bus"

export default {
    name: "ProductSubType",
    props: [
        "subTypes"
    ],
    data() {
        return {
        }
    },
    methods: {
        showMartinaType(product) {
            this.$store.commit("setWingType", product.abbreviation);
            this.$store.commit("setWingTypeBG", product.abbreviationBG);
            this.$store.commit("setMechanismType");
            this.$store.commit("setMechanismTypeBG");
            this.$store.commit("resetCurrentProduct");
            EventBus.$emit("showMartinaType");
            EventBus.$emit("closeSubTypes");
        }
    }
}
</script>

<style lang="scss">
.sub-products {
    .sub-product {
        background-color: rgb(246, 194, 68);
        text-align: center;
        margin-bottom: 0.188rem;
        width: same-as-height;
        z-index: 1;

        .sub-product-image {
            width: 3rem;
            height: 3rem;
        }
    }

    .sub-product:hover {
        cursor: pointer;
    }
}
</style>