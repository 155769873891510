<template>
  <div class="articles-wrapper col-12 col-md-12 col-lg-12">
      <div class="articles-title-wrapper col-12 col-md-12 col-lg-12">
          <ArticlesTitle />
      </div>
      <div class="articles-main-wrapper col-12 col-md-12 col-lg-12">
          <ArticlesMain />
      </div>
  </div>
</template>

<script>
import ArticlesTitle from './AriclesTitle'
import ArticlesMain from './ArticlesMain'

export default {
    name: 'ArticlesWrapper',
    components: {
        ArticlesTitle,
        ArticlesMain
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.articles-wrapper {
    height: 100vh;
    max-height: 100vh;
    margin-left: .938rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .articles-title-wrapper {
        max-height: .938rem;
    }

    .articles-main-wrapper {
        max-height: 85%;
    }
}
</style>