<template>
  <div class="articles-main col-12 col-md-12 col-lg-12">
      <div class="table col-9 col-md-9 col-lg-9">
          <ArticlesList />
      </div>
      <div class="buttons-menu col-2 col-md-2 col-lg-2">
          <ArticlesButtons />
      </div>
  </div>
</template>

<script>
import ArticlesList from './ArticlesList'
import ArticlesButtons from './ArticlesButtons'

export default {
    name: 'ArticlesMain',
    components: {
        ArticlesList,
        ArticlesButtons
    },
    data() {
        return {}
    }
}
</script>

<style>
.articles-main {
    background-color: rgba(246, 194, 68, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-height: 100%;
    height: 100%;
}
</style>