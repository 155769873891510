import axios from "axios";

export async function verifyProductCompleted(product) {
    const response = await axios.post("https://ugliest-jonie-bobirok.koyeb.app/validate", { product },
        { validateStatus: (status) => {
            return status < 500;
        }});

    if (response.status === 400)
        return response.data;

    return [];
}