<template>
<div class="login-wrapper col-12 col-md-12 col-lg-12">
  <div class="login-main col-12 col-md-6 col-lg-4">
      <div class="logo-description col-12 col-md-12 col-lg-12">
          <img class="auth-logo-img" src="../../public/images/trixx-logo-white.svg" alt="error">
          <h2>MACO HARDWARE CALCULATOR</h2>
      </div>
      <div class="authentication-fields col-12 col-md-12 col-lg-12">
          <span class="col-12 col-md-6 col-lg-5">
              <label class="col-12 col-md-12 col-lg-12" for="">Потребителско име:</label>
              <input v-model="username" type="text">
          </span>
          <span class="col-12 col-md-6 col-lg-5">
              <label class="col-12 col-md-12 col-lg-12" for="">Парола:</label>
              <input v-model="password" type="password">
          </span>
          <v-btn @click="logIn()" class="login-btn">Вход</v-btn>
      </div>
      <div class="copyright col-12 col-md-12 col-lg-12">
          <p>&copy; 2020 Vasil Androv, Boris Rokanov</p>
      </div>
  </div>
</div>
</template>

<script>
import * as axios from 'axios';
import * as jwt from 'jsonwebtoken';
import EventBus from '../plugins/event-bus';

export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        async logIn() {
            var token = '';
            axios.post('https://maco-api.herokuapp.com/login?username=' + this.username + '&password=' + this.password)
            .then(result => {
                token = result.data;
                localStorage.setItem('maco-token', token);
                var firstName = jwt.decode(localStorage.getItem("maco-token")).firstName;
            
                this.$router.push({name: 'order'})
                EventBus.$emit('loggedIn', firstName)

            })
            .catch(() => {
                EventBus.$emit('wrongCredentials');
            })
        }
    }
}
</script>

<style lang="scss">
.login-wrapper {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    background-image: linear-gradient(to top right, rgb(107, 158, 156), rgb(56, 63, 67), rgb(56, 63, 67), rgb(107, 158, 156));
}
.login-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto auto;
    height: 50rem;
    max-height: 100vh;
    color: white;

    .logo-description {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-height: 20%;
        align-items: center;
        margin-top: 2.5rem;

        h2 {
            font-size: 1.5rem;
            font-family: "Open Sans", sans-serif;
        }

        .auth-logo-img {
            width: 12.125rem;
            height: 8.125rem;
        }
    }

    .authentication-fields {
        max-height: 14rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        margin: auto;
        font-family: "Open Sans", sans-serif;

        input {
            background-color: white;
            border-radius: 1.563rem 1.563rem 1.563rem 1.563rem;
            height: 2.1875rem;
            width: 12.5rem;
            text-align: center;
        }

        input:focus {
            border: .125rem solid rgb(107, 158, 156);
            outline: none;
        }

        .login-btn {
            background-color: rgb(107, 158, 156) !important;
            color: white;
            border-radius: 1.563rem 1.563rem 1.563rem 1.563rem;
        }
    }

    .copyright {
        max-height: 10%;
        align-self: flex-end;
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        text-align: center;

        p {
            margin-bottom: 0px !important;
        }
    }
}

@media only screen and (max-width: 400px) {
    body {
        color: black !important;
    }
    .logo-main {
        .authentication-fields {
            max-height: 1rem;
            height: 1rem;
            margin: .063rem !important;
        }
    }
}
</style>