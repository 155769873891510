<template>
  <div class="profile-system-main col-12 col-md-12 col-lg-12">
      <p>3. Профилна система</p>
      <div class="profile-system-types col-11 col-md-11 col-lg-11">
          <div v-for="(profile, index) in profileSystems" :key="index" class="profile-type col-12 col-md-12 col-lg-12">
              <v-checkbox @change="updateProfileSystem(profile)" :disabled="profileSelected(profile)" :label="profile.name" :color="`#f6c244`" />
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'ProfileSystem',
    data() {
        return {
            profileSystems: [
                {
                    name: 'Salamander / Profilink',
                    selected: false,
                    abbreviation: 'salamander',
                    short: 'Sal',
                    type: 'pvc'
                },
                {
                    name: 'Rehau / Viva Plast',
                    selected: false,
                    abbreviation: 'rehau',
                    short: 'Reh',
                    type: 'pvc'
                },
                {
                    name: 'Veka',
                    selected: false,
                    abbreviation: 'veka',
                    short: 'Veka',
                    type: 'pvc'
                },
                {
                    name: 'KBE / Trocal / Koemmerling 76mm.',
                    selected: false,
                    abbreviation: 'kbe',
                    short: 'KBE',
                    type: 'pvc'
                },
                {
                    name: 'Gealan',
                    selected: false,
                    abbreviation: 'gealan',
                    short: 'Gea',
                    type: 'pvc'
                },
                {
                    name: 'Aluplast',
                    selected: false,
                    abbreviation: 'aluplast',
                    short: "Alu",
                    type: 'pvc'
                },
                {
                    name: 'Schucco Living CT 70 / WP',
                    selected: false,
                    abbreviation: 'schucco',
                    short: 'ALU-V',
                    type: 'alu'
                },
                {
                    name: 'ETEM AL / ALUMIL AL',
                    selected: false,
                    abbreviation: 'etemal',
                    short: 'ETEM-AL',
                    type: 'alu'
                },
                {
                    name: 'WP AL / VIVA PLAST AL',
                    selected: false,
                    abbreviation: 'wpal',
                    short: 'WP',
                    type: 'alu'
                }
            ]
        }
    },
    methods: {
        profileSelected(profile) {
            return this.profileSystems.some(_ => _.selected && _.name !== profile.name)
        },
        updateProfileSystem(profile) {
            profile.selected = !profile.selected;

            if(this.profileSystems.some(_ => _.selected)) {
                var profileSystemString = this.profileSystems.find(_ => _.selected).abbreviation;

                if(this.$store.state.profileSystem !== '') {
                    this.$store.commit('setProfileSystem', profileSystemString);
                    this.$store.commit('setProfileSystemShort', profile.short);
                    this.$store.commit('setProfileType', profile.type);
                } else {
                    this.$store.commit('setProfileSystem', '');
                    this.$store.commit('setProfileSystemShort', '');
                    this.$store.commit('setProfileType', '');
                }
            }
        }
    }
}
</script>

<style lang="scss" scooped>
.profile-system-main {
    height: 20.281rem;
    color: white;
    font-family: "Open Sans", sans-serif;
    background-color: rgba(107, 158, 156, 0.4);
    margin-bottom: .625rem;

    p {
        padding: .25rem !important;
        font-size: 1.25rem;
        margin: 0px;
    }

    .profile-system-types {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 1.25rem !important;

        .v-label {
            margin: 0px;
            color: white;
            font-size: .875rem;
        }

        .v-input__slot {
            margin: 0px !important;
        }

        .v-messages {
            display: none;
        }

        .profile-type {
            padding-bottom: 0.49rem !important;
        }
    }
}
</style>