<template>
  <div class="opening-direction-main col-12 col-md-12 col-lg-12" v-bind:class="{ 'disabled': isDisabled }">
      <p>2. Посока на отваряне</p>
      <div class="left-right col-8 col-md-8 col-lg-8">
            <v-checkbox v-on:change="updateOpeningDirection()" v-model="left" :disabled="right" label="Ляв" :color="`#f6c244`"/>
            <v-checkbox v-on:change="updateOpeningDirection()" v-model="right" :disabled="left" label="Десен" :color="`#f6c244`" />
      </div>
  </div>
</template>

<script>
export default {
    name: 'OpeningDirection',
    data() {
        return {
            left: false,
            right: false,
            isDisabled: false
        }
    },
    methods: {
        updateOpeningDirection() {
            var openingDirectionString = '';

            if(this.left) openingDirectionString = 'left';
            else if(this.right) openingDirectionString = 'right';
            else openingDirectionString = '';

            this.$store.commit('setOpeningDirection', openingDirectionString);
        }
    },
    mounted() {
        this.isDisabled = this.$store.state.currentProduct.mechanismType === "padasht";
    }
}
</script>

<style lang="scss">
.disabled {
    pointer-events: none;
    opacity: .5;
}

.opening-direction-main {
    height: 3.938rem;
    font-family: "Open Sans", sans-serif;
    background-color: rgba(107, 158, 156, 0.4);
    color: white;
    margin-bottom: .625rem;

    p {
        padding: .25rem !important;
        font-size: 1.25rem;
        margin: 0px;
    }
    
    .left-right {
        padding-left: 1.5rem !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 1.563rem !important;

        .v-label {
            margin: 0px;
            color: white;
            font-size: .875rem;
        }
    }
}
</style>