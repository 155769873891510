<template>
    <div class="specifications-main col-12 col-md-12 col-lg-12">
        <div class="top-bar col-12 col-md-12 col-lg-12">
            <h2>{{ title }}</h2>
            <p>Моля, въведете размери по фалц на крилото.</p>
        </div>
        <div class="bottom-bar col-12 col-md-12 col-lg-12">
            <div class="left-bar col-6 col-md-6 col-lg-6">
                <SizeAndAmount />
                <Other />
            </div>
            <div class="right-bar col-4 col-md-4 col-lg-4">
                <OpeningDirection />
                <ProfileSystem />
                <Color />
                <AdditionalElements />
            </div>
            <div class="button-bar col-1 col-md-1 col-lg-1">
                <v-btn @click="addCurrentProduct()" class="add-button"><img
                        src="../../../public/images/mcs-button-arrow-right.png"></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import SizeAndAmount from './SizeAndAmount'
import Other from './Other'
import OpeningDirection from './OpeningDirection'
import ProfileSystem from './ProfileSystem'
import Color from './Color'
import AdditionalElements from './AdditionalElements'
import EventBus from '../../plugins/event-bus'
import { verifyProductCompleted } from '../../services/product-verification-service'

export default {
    name: 'SpecificationsMain',
    components: {
        SizeAndAmount,
        Other,
        OpeningDirection,
        ProfileSystem,
        Color,
        AdditionalElements
    },
    data() {
        return {
            title: ''
        }
    },
    methods: {
        async addCurrentProduct() {
            const validationErrors = await verifyProductCompleted(this.$store.state.currentProduct);
            if (validationErrors.length) {
                validationErrors.forEach(error => {
                    this.$notify({
                        group: 'bad-product',
                        title: 'Грешка',
                        text: error.message,
                        classes: "vue-notification-bad-product",
                        duration: 5000,
                        width: 350,
                        key: error.message
                    })
                })
                return;
            }
            const productExistsInCart = this.$store.state.listOfProducts.some(_ => JSON.stringify(_) === JSON.stringify(this.$store.state.currentProduct));

            if (productExistsInCart) {
                this.$notify({
                    group: 'bad-product',
                    title: 'Внимание',
                    text: 'Продукт с тези параметри съществува, затова бройките са сумирани!',
                    classes: "vue-notification-bad-product",
                    duration: 5000,
                    width: 350,
                    key: "warning-product-exists"
                })
            }
            this.$store.commit('addCurrentProductToList');
            EventBus.$emit('product-list-updated');

        }
    },
    mounted() {
        this.title = this.$store.state.currentProduct.mechanismTypeBG +
            (this.$store.state.currentProduct.wingBG ?
                (', ' + this.$store.state.currentProduct.wingBG) : "");
    }
}
</script>

<style lang="scss">
.specifications-main {
    max-height: 100%;
    height: 100%;

    .top-bar {
        color: white;
        padding-left: 1.25rem !important;
        height: 5.313rem;
    }

    .bottom-bar {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .left-bar {
            height: 40rem;
            max-height: 40rem;

            .v-input {
                margin: 0px !important;
            }
        }

        .right-bar {
            height: 40rem;
            max-height: 40rem;

            .v-input {
                margin: 0px !important;
            }
        }

        .button-bar {
            height: 44.2rem;

            .add-button {
                height: 100% !important;
                background-color: rgb(246, 194, 68) !important;

                img {
                    width: 3.125rem;
                    height: 3.125rem;
                }
            }
        }
    }
}
</style>