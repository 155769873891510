<template>
    <div class="product-list-main col-8 col-md-12 col-lg-12">
        <div v-for="(product, index) in this.listOfProducts" :key="index" class="product col-12 col-md-12 col-lg-12">
            <ProductType ref="product" v-bind:image="product.image" v-bind:subTypes="product.subTypes" 
                v-bind:name="product.name" v-bind:abbreviation="product.abbreviation"/>
        </div>
    </div>
</template>

<script>
import ProductType from './ProductType'
import EventBus from '../plugins/event-bus'

export default {
    name: 'ProductList',
    components: {
        ProductType
    },
    data() {
        return {
            listOfProducts: [
                {
                    image: 'mcs-ico-dvuosov',
                    name: 'Двуосов',
                    abbreviation: 'dvuosov',
                    subTypes: [
                        {
                            img: 'mcs-ico-dvuosov',
                            abbreviation: 'ednokril',
                            abbreviationBG: 'еднокрил'
                        },
                        {
                            img: 'mcs-ico-dvuosov-dvukril',
                            abbreviation: 'dvukril',
                            abbreviationBG: 'двукрил'
                        }
                    ]
                }, 
                {
                    image: 'mcs-ico-ednoosov',
                    name: 'Едноосов',
                    abbreviation: 'ednoosov',
                    subTypes: [
                        {
                            img: 'mcs-ico-dvuosov',
                            abbreviation: 'ednokril',
                            abbreviationBG: 'еднокрил'
                        },
                        {
                            img: 'mcs-ico-dvuosov-dvukril',
                            abbreviation: 'dvukril',
                            abbreviationBG: 'двукрил'
                        }
                    ]
                },
                {
                    image: 'mcs-ico-padasht',
                    name: 'Падащ',
                    abbreviation: 'padasht',
                    subTypes: []
                },
                {
                    image: 'mcs-ico-vrata',
                    name: 'Входна врата',
                    abbreviation: 'vrata',
                    subTypes: []
                },
                {
                    image: 'mcs-ico-vw',
                    name: 'SKB',
                    abbreviation: 'skb',
                    subTypes: []
                },
                {
                    image: 'mcs-ico-hs',
                    name: 'HS',
                    abbreviation: 'hs',
                    subTypes: []
                },
            ]
        }
    },
    created() {
        EventBus.$on('close-all-product-types', () => {
            this.$refs.product.map(_ => _.closeSubTypes())
        })
    }
}
</script>

<style lang="scss">
* {
    padding: 0 !important;
}
.product-list-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product {
        padding-bottom: .188rem !important;
        padding: 0 !important;
        margin-bottom: .188rem;
    }
}
</style>